
.Navbar {
    background-color: #FFE4C8;
    height: 85px;
    top: 0;
}

.logo {
    height: 60px;
}

@media only screen and (min-width: 650px) {
    .logo {
        height: 70px;
        padding: 0 10px;
    }
}

@media only screen and (max-width: 575px) {
    .signInButton {
        border-radius: 25px;
        background-color: white;
        margin: 30px 18px 5px 18px;
    }
}

.brand {
    display: inline-flex;
    font-style: italic;
    font-size: 110%;
    color: #484848;
}

.dropdown-item:focus {
    background-color: #fff !important;
}



/* Grey Title Bar */

.pageTitleDiv {
    top: 85px;
    position: fixed;
    width: 100%;
    z-index: 100;
    background-color: #484848;
    color: white;
    height: 60px;
    align-items: center;
}

.pageTitle {
    font-size: 26px;
    margin-bottom: 0;
}

.moduleNumber {
    border: 1px solid white;
    border-radius: 5px;
    padding: 0 10px;
    margin-right: 10px;
}

.pageSubTitleDiv {
    top: 145px;
    position: fixed;
    width: 100%;
    height: 38px;
    z-index: 100;
    background-color: #484848;
    color: white;
}

.pageSubTitle {
    margin: 0 0 10px 0;
    border: 1px solid white;
    padding: 1px 7px 2px 7px;
    border-radius: 5px;
}
