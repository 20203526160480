footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #3B3737;
    margin-top: 50px;
    font-size: 14px;
}

footer a {
    color: white;
}

footer a:hover {
    text-decoration: none!important;
}

.fontAwesomeIcon {
    color: #991315;
}

.fontAwesomeIcon:hover {
    color: #491217;
}

/* Footer & Newsletter */

#newsletterTitle {
    font-family: 'freight-display-pro', serif;
    font-size: 3.5em;
    font-weight: 300;
    letter-spacing: 1.6px;
}

.footer-newsletter {
    background-color: #FFE4C8;
    width: 95%;
    margin: auto !important;
    position: relative;
    top: -40px;
}


.footer-copyright {
    display: inline-block;
    height: 40px;
}

@media screen and (max-width: 850px) {
    .footer-copyright {
        display: block;
        float: none !important;
        height: 12px;
    }
    .footer-copyright-div {
        text-align: center;
    }
    .footer-right {
        padding-right: 0px !important;
    }
    .footer-left {
        padding-left: 0px !important;
    }
}

.footer-right {
    float: right;
    padding-right: 50px;
}

.footer-left {
    float: left;
    padding-left: 50px;
}

#newsletter-input {
    width: 93%;
    height: 130%;
}

#newsletterButton {
    position: absolute;
    left: 85%;
    margin: 0px 3px !important;
    padding: 8px 13px !important;
}

.btn-link {
    text-transform: none !important;
}