.PageWrapper {
    padding-top: 170px;
    min-height: 100vh;
    position: relative;

}
.ContentWrapper {
    padding-bottom: 250px;
}

.bigNavContentWrapper {
    padding-bottom: 200px;
}

a, a:hover {
    text-decoration: none !important;
}

.inner{
    overflow: hidden;
}

/* Landing Page */

#landingSupTitle {
    font-family: 'Montserrat', sans-serif;
    color: #484848;
    margin-top: 10px;
    margin-left: 40px;
    margin-bottom: 0;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 1.6px;
}

#landingTitle {
    color: #212529;
    font-family: 'freight-display-pro', serif;
    font-style: italic;
    font-size: 5em;
    font-weight: 300;
    letter-spacing: 1.6px;
    z-index: 1000;
    margin-left: 4px;
}

#landingSubTitle {
     padding-top: 20px;
     margin-left: 40px;
 }

#landingLink {
    padding-top: 20px;
    margin-left: 40px;
    font-style: italic;
}

#landingImage {
    transform: translate(50px, 0);
    z-index: -1000;
}

#beige {
    margin: 100px 0 !important;
    width: 100% !important;
    max-width: 100% !important;
    padding: 60px 150px 0 150px;
    background-color: #FFE4C8;
}

#landingVideo {
    transform: translate(0, 50px);
}

/* Modules */
.module-card {
    background-color: white;
    transition: all 0.3s ease;
    margin: 30px 0;
    border-radius: 25px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    position: relative;
    overflow: hidden;
}

.module-session-card {
    background-color: #FFE4C8;
    transition: all 0.3s ease;
    margin: 30px 0;
    border-radius: 25px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    position: relative;
    overflow: hidden;
}

.sessionRow {
    background-color: #FFE4C8;
    z-index: 0;
}

.module-card:hover, .module-session-card:hover {
    transform: scale(1.05);
}

.module-done-bar {
    background-color: #33941A;
    width: 100%;
    height: 6px;
}

.module-link, .module-link:hover {
    color: inherit;
    text-decoration: none;
}


/* COLORS */
.color-success {
    color: #33941A;
}

/* Buttons & Inputs */

.checkBox:hover {
    cursor: pointer;
}

.MuiCheckbox-colorSecondary.Mui-checked {
    color: #991315 !important;
}

.MuiInput-underline:after {
    border-bottom-color: #991315 !important;
}

.MuiFormLabel-root.Mui-focused {
    color: #991315 !important;
}

/* Session Booking */
#ycbmiframelisabethstraub1 {
    width: 100%;
    height: 990px;
    border: 0;
    background-color: transparent;
}

