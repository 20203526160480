.imageOverlay:hover {
    opacity: 0.8;
    transition: .5s ease;
}

.rowC {
    display:flex;
    flex-direction:row;
    width: 100%;
}
